@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121212;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.container {
  width: 100vw;
  display: flex;
  place-content: center;
  margin: -150px auto;
  overflow: hidden;
}

.item {
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}